<template>
  <div class="operate_content">
    <div class="order_top">
      <div class="storeCenter_companyinfo_top_left">
        <img v-if="companyinfo.companyLogo" :src="companyinfo.companyLogo" alt="" />
        <img v-else src="../../assets/image/Group 3237.png" alt="" />
        <div class="storeCenter_companyinfo_top_left_flex">
          <div style="display: flex; align-companyinfos: center">
            <h5 style="display: inline-block; margin: 0; font-size: 20px">
              {{ companyinfo.companyFullName }}
            </h5>
          </div>
          <div class="tag_item">
            <el-tag
              size="mini"
              effect="plain"
              style="margin-right: 10px"
              v-for="(tt, ii) in companyinfo.state"
              :key="ii"
            >
              {{ tt }}
            </el-tag>
          </div>
          <div class="storeCenter_companyinfo_top_left_flex_tag">
            <span v-if="companyinfo.archivesTable" style="display: flex">
              <span
                v-for="(o, index) in companyinfo.archivesTable.split(',').slice(0, 4)"
                :key="index"
              >
                <span v-if="index < 4" class="office">{{ o }}</span>
              </span>
            </span>
            <el-popover
              placement="right"
              width="430"
              trigger="hover"
              v-if="companyinfo.archivesTable"
            >
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  style="
                    border: 1px solid #559eff;
                    color: #4e93fb;
                    box-sizing: border-box;
                    padding: 1px 6px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  v-for="(o, indexs) in companyinfo.archivesTable.split(',')"
                  :key="indexs"
                  >{{ o }}</span
                >
              </div>
              <span
                class="office"
                slot="reference"
                v-if="companyinfo.labelList && companyinfo.labelList.length >= 3"
                >{{ companyinfo.labelList.length }}+</span
              >
            </el-popover>
          </div>
          <div class="address">
            <span>所在地区： {{ companyinfo.province }}-{{ companyinfo.city }}</span>
          </div>
        </div>
      </div>
      <div class="buttom_info">
        <el-button
          style="color: #146aff"
          @click="companyInfo"
          class="primary_buttom"
          type="primary"
          plain
          icon="el-icon-office-building"
          >基本信息</el-button
        >
        <el-button @click="goRouter" type="primary">返回上一级</el-button>
      </div>
    </div>

    <div class="form_info">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in typeList"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === indexType ? 'active' : ''"
        >
          <span>{{ item.name }} ({{ item.num }})</span>
        </div>
      </div>

      <div class="form_title">
        <div class="search_left">
          <el-input
            placeholder="请输入资讯名称/描述"
            v-model="queryInfo.queryConditions"
            class="input-with-select"
            style="width: 500px"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
        <div class="form_title_right">
          <i class="el-icon-refresh"></i>
          <span>内容匹配更新时间：{{ companyinfo.pushTime }}</span>
          <span style="margin-left: 8px" v-if="companyinfo.num"
            >更新 <em>{{ companyinfo.num }}</em> 条</span
          >
        </div>
      </div>
      <div class="tab_txt">
        <el-table
          ref="multipleTable"
          :data="listData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column
            label="编号"
            align="center"
            width="110px"
            key="foreignKey"
            prop="foreignKey"
          >
          </el-table-column>

          <el-table-column label="资讯名称" align="center" width="190" key="informationName">
            <template v-slot="{ row }">
                <el-popover placement="right" width="200" trigger="hover">
                  <div>{{ row.informationName }}</div>
                  <div slot="reference">
                    {{ truncatedText(row.informationName) }}
                  </div>
                </el-popover>
            </template>
          </el-table-column>

          <el-table-column label="封面图" key="cover" prop="cover" align="center">
            <template v-slot="{ row }">
              <div class="imgbox">
                <div class="img">
                  <img width="69" height="48" :src="row.cover" alt="" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="informationType"
            label="资讯类型"
            align="center"
            width="100"
            prop="informationType"
          >
            <template v-slot="{ row }">
              <div class="correlationBox">
                <div class="correlation" v-if="row.informationType">
                  {{ informationTypeVal(informationType[row.informationType - 1]) }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" key="labels" prop="labels" label="标签" width="200px">
            <template v-slot="{ row }">
              <span v-for="(o, index) in row.labels" :key="index">
                <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
              </span>
              <el-popover placement="right" width="230" trigger="hover">
                <div
                  class="hidden_label_box"
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <span
                    class="span_tag"
                    v-for="(o, index) in row.labels && row.labels.length
                      ? row.labels.filter((el, index) => index > 3)
                      : []"
                    :key="index"
                    style="
                      font-family: 'PingFang SC';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      color: #4e93fb;
                      margin-right: 10px;
                    "
                    >#{{ o }}</span
                  >
                </div>
                <span
                  style="color: #4e93fb"
                  slot="reference"
                  v-if="row.labels && row.labels.length > 4"
                  >...{{ row.labels.length - 4 }}+</span
                >
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column key="brief" prop="brief" label="一句话描述" width="220" align="center">
            <template v-slot="{ row }">
                <el-popover placement="right" width="200" trigger="hover">
                  <div>{{ row.brief }}</div>
                  <div slot="reference">
                    {{ truncatedText(row.brief) }}
                  </div>
                </el-popover>
            </template>
          </el-table-column>

          <el-table-column
            label="分享"
            width="120"
            key="shareResultList"
            prop="shareResultList"
            align="center"
          >
            <template v-slot="{ row }">
              <div v-if="row.shareResultList">
                <div :class="[row.spread ? 'shareResultList' : 'spreadwrap']">
                  <div
                    class="tooltip_box"
                    v-for="(item, index) in row.shareResultList"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        <div>{{ item.shareName }}</div>
                        <div style="margin-top: 5px">
                          <span>{{ item.position }}|{{ item.phone }}</span>
                        </div>
                      </div>
                      <el-button type="text">
                        {{ item.shareName }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="row.shareResultList.length > 2">
                  <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary" plain
                      >展开<el-icon class="el-icon-arrow-down"></el-icon
                    ></el-button>
                  </div>
                  <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary"
                      >收起<el-icon class="el-icon-arrow-up"></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="correlation" prop="correlation" label="关联度" align="center">
            <template v-slot="{ row }">
              <span style="color: #fd6161" v-if="row.correlation">{{ row.correlation }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="匹配类型" key="pushType" prop="pushType" align="center">
            <template v-slot="{ row }">
              <span v-if="!row.correlation">人工匹配</span>

              <el-tooltip v-else placement="top">
                <div slot="content">
                  <div>{{ row.userName }}</div>
                  <div style="padding: 10px 0px">
                    <span>版本号：{{ row.versionNumber }}</span>
                  </div>
                  <div>
                    <span>{{ row.pushType }}</span>
                  </div>
                </div>
                <el-button type="text"> 算法匹配 </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="推送人" prop="userName" key="userName" width="160" align="center">
            <template v-slot="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="推送时间" key="pushTime" align="center" width="110">
            <template v-slot="{ row }">
              <div>{{ row.pushTime | date }}</div>
              <div>{{ row.pushTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="160">
            <template v-slot="{ row }">
              <div class="btn">
                <span class="pass" @click="Jumpinfo(row)" style="margin-right: 10px">详情</span>
                <span class="pass" v-if="row.isShelf == 0" @click="isShelf(row)">下架</span>
                <span class="un_pass" v-if="row.isShelf == 1" @click="isShelf(row)">上架</span>
                <span class="pass" @click="sendingClick(row)" style="margin-left: 10px"
                  >人工发送</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="Pagination">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog
      title="人工发送"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="closeClick"
      center
    >
      <div class="dia_form">
        <el-form :model="diaFrom" label-width="100px">
          <el-form-item label="资讯名称">
            <div class="name">{{ diaFrom.name }}</div>
          </el-form-item>
          <el-form-item label="服务链接标语">
            <el-input type="textarea" :rows="5" disabled v-model="diaFrom.slogan"> </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeClick">取 消</el-button>
        <el-button type="primary" @click="copyLink">复制链接</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { companyContentPage } from '@/api/datement'
import { pushedCompanyShelf } from '@/api/content.js'
import { selectArchivesContent, getArchivesContentNum, generateLink } from '@/api/archives.js'
const defaultQueryInfo = Object.freeze({
  companyId: '',
  tabloidId: '',
  informationType: null, //1应用场景，2数智技术，3行业动态，4案例视频，5数智产品，6服务商,7政策解读,8组织人才,9专家观点,10数智案例,11场景知识
  matchingType: null, //匹配类型 null表示全部 1是人工算法 2是匹配算法
  orderByParam: '', //排序字段+ DESC或ASC
  pageNum: 1,
  pageSize: 10,
  queryConditions: '', //模糊查询条件
  type: 1 //1 内容资讯； 2 数智活动；3 平台专家 4行业报告 ；5 数智问题
})
const defaultDiaFrom = Object.freeze({
  slogan: '',
  name: ''
})
export default {
  name: 'operateContent',
  computed: {
    archiveId() {
      return this.$route.query.archiveId
    },
    companyId() {
      return this.$route.query.companyId
    }
    // countClass() {
    //   let className
    //   return (val) => {
    //     switch (val) {
    //       case '绑定建群':
    //         className = 'class_name1'
    //         break
    //       case '企业调研':
    //         className = 'class_name2'
    //         break
    //       case '小程序绑定':
    //         className = 'class_name3'
    //         break
    //       case '是否会员':
    //         className = 'class_name4'
    //         break
    //       case '是否专家服务':
    //         className = 'class_name5'
    //         break
    //       case '是否有明确需求':
    //         className = 'class_name6'
    //         break
    //       case '客户冻结':
    //         className = 'class_name7'
    //         break
    //       case '正常运营':
    //         className = 'class_name8'
    //         break
    //       case '会员到期':
    //         className = 'class_name9'
    //         break
    //       case '甄选服务':
    //         className = 'class_name10'
    //         break

    //       default:
    //         className = ''
    //         break
    //     }

    //     return className
    //   }
    // }
  },
  data() {
    return {
      diaFrom: { ...defaultDiaFrom },
      dialogVisible: false,
      ids: [],
      queryInfo: { ...defaultQueryInfo },
      indexType: '',
      total: 0,
      informationType: [
        {
          id: 1,
          val: '应用场景'
        },
        {
          id: 2,
          val: '数智技术'
        },
        {
          id: 3,
          val: '行业动态'
        },
        {
          id: 4,
          val: '数智案例'
        },
        {
          id: 5,
          val: '数智产品'
        },
        {
          id: 6,
          val: '服务商'
        },
        {
          id: 7,
          val: '政策解读'
        },
        {
          id: 8,
          val: '组织人才'
        },
        {
          id: 9,
          val: '专家观点'
        },
        {
          id: 10,
          val: '数智案例'
        },
        {
          id: 11,
          val: '场景知识'
        }
      ],
      //类型
      typeList: [
        {
          id: '',
          name: '全部内容',
          num: 0
        },
        {
          id: 2,
          name: '数智技术',
          num: 0
        },
        {
          id: 1,
          name: '应用场景',
          num: 0
        },
        {
          id: 3,
          name: '行业动态',
          num: 0
        },
        {
          id: 10,
          name: '数智案例',
          num: 0
        },
        {
          id: 5,
          name: '数智产品',
          num: 0
        },
        {
          id: 6,
          name: '服务商',
          num: 0
        },
        {
          id: 7,
          name: '政策解读',
          num: 0
        },
        {
          id: 9,
          name: '专家观点',
          num: 0
        }
      ],
      loading: false,
      listData: [], //表格数据
      companyinfo: {} //头部数据
    }
  },
  created() {
    this.getTopData() //获取头部数据
    this.getCount() //获取类型数量
    this.search() //获取表格数据
  },
  methods: {
    // 限制字数
    truncatedText(val) {
      if (!val) return
      if (val.length > 6) {
        return val.slice(0, 6) + '...'
      }
      return val
    },
    // 资讯类型
    informationTypeVal(item) {
      if (item) {
        return item.val
      } else {
        return null
      }
    },
    async copyTextToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (err) {
        this.$message.warning('复制失败')
      }
    },
    // 点击取消
    closeClick() {
      this.diaFrom = { ...defaultDiaFrom }
      this.dialogVisible = false
    },
    // 复制链接
    copyLink() {
      if (this.diaFrom.slogan) {
        this.copyTextToClipboard(this.diaFrom.slogan)
      } else {
        this.$message.warning('服务链接标语暂无')
      }
    },
    //展开收缩
    spreadText(row) {
      row.spread = !row.spread
    },
    // 人工发送
    async sendingClick(row) {
      const { data: res } = await generateLink({ informationId: row.foreignKey })
      if (res.resultCode === 200) {
        this.diaFrom.slogan = res.message
        this.diaFrom.name = row.informationName
        this.dialogVisible = true
      }
    },
    //跳转详情
    Jumpinfo(row) {
      this.$router.push({
        path: '/content/redact',
        query: {
          id: row.foreignKey,
          headTabActive: 0
        }
      })
    },
    //单个上下架
    isShelf(row) {
      const query = {
        companyIds: [this.companyId],
        id: row.foreignKey,
        idType: '资讯',
        isShelf: row.isShelf == 0 ? '1' : '0'
      }
      const that = this
      this.$alert(`是否确认${row.isShelf == 0 ? '下架' : '上架'}该企业？`)
        .then(function () {
          return pushedCompanyShelf(query)
        })
        .then(() => {
          this.search()
          this.$message.success(`${row.isShelf == 0 ? '下架' : '上架'}成功`)
        })
        .catch(() => {})
    },
    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.foreignKey)
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    },
    // 获取数据
    async search() {
      this.loading = true
      this.queryInfo.companyId = this.companyId
      const { data: res } = await companyContentPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.listData.forEach((el) => {
          if (el.labels) {
            el.labels = el.labels.split('~')
          }
          this.$set(el, 'spread', false)
        })
        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    // 获取数量
    async getCount() {
      const { data: res } = await getArchivesContentNum({ companyId: this.companyId })
      if (res.resultCode === 200) {
        this.typeList[0].num = res.data.totalNum
        this.typeList[1].num = res.data.technologyNum
        this.typeList[2].num = res.data.applyNum
        this.typeList[3].num = res.data.industryNum
        this.typeList[4].num = res.data.planNum
        this.typeList[5].num = res.data.productNum
        this.typeList[6].num = res.data.serverNum
        this.typeList[7].num = res.data.policyNum
        this.typeList[8].num = res.data.specialistNum
      }
    },
    //类型选择
    storeClick(id) {
      this.queryInfo.informationType = id
      this.indexType = id
      this.search()
    },
    // 点击基本信息
    companyInfo() {
      this.$router.push({
        path: '/customer/addenterprise',
        query: { id: this.companyId, headTabActive: 1 }
      })
    },
    // 返回上一层
    goRouter() {
      this.$router.go(-1)
    },
    // 获取头部数据
    async getTopData() {
      const { data: res } = await selectArchivesContent({ companyId: this.companyId })
      if (res.resultCode === 200) {
        this.companyinfo = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.operate_content {
  width: 100%;

  .dia_form {
    width: 100%;
    margin-top: 20px;

    .name {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }

    .slogan {
      width: 414px;
      height: 115px;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #f3f3f3;
    }
  }

  .form_info {
    padding: 0px 0px 10px 0px;
    background: #fff;
    margin-top: 20px;

    .form_title {
      width: 100%;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      padding: 24px;
      background-color: #fff;
      justify-content: space-between;
      align-items: center;

      .search_left {
        width: 500px;
        margin-right: 40px;
        ::v-deep .el-input-group__append {
          border-right: 0;
        }
        ::v-deep .el-button {
          border: #448aff 1px solid;
          color: #fff;
          background-color: #448aff;
        }
      }

      .form_title_right {
        display: flex;
        align-items: center;
        color: #969495;
        font-size: 14px;
        font-weight: 400;

        i {
          color: #4e93fb;
          font-size: 16px;
          margin-right: 4px;
        }

        em {
          color: #4e93fb;
          font-weight: 400;
          font-style: normal;
        }
      }
    }

    .tab_txt {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 24px;
    }

    .form {
      padding-top: 15px;
    }
    .store_table {
      padding-left: 10px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      background: #fafafa;

      .store_table_item {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0px 10px;
        margin-right: 30px;
        border-radius: 1px;
      }

      .active {
        padding: 0px 10px;
        color: #4e93fb;
        font-size: 16px;
        margin-right: 30px;
        border-radius: 1px;
        background: #ffffff;
      }
    }
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
    .shareResultList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .tooltip_box {
      padding: 0px 5px;
      display: inline-block;
    }
    .spreadwrap {
      -webkit-line-clamp: 2; //设置几行
      display: -webkit-box; //设置为伸缩盒弹性盒子展示
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //设置超出部分以省略号展示
      -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
    }
    .span_tag {
      color: #4e93fb;
      cursor: pointer;
    }
    .input-with {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      margin-left: 20px;
      .input-with-select {
        width: 400px;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }

  .buttom_info {
    margin-left: 12px;
  }
  .tag_item {
    flex: 1;
    margin-left: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .tag_item_div {
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      padding: 3px 8px;
      color: #999999;
      background-color: rgba(153, 153, 153, 0.1);
      margin-right: 8px;
    }

    .class_name1 {
      color: rgba(33, 122, 247, 1);
      background-color: rgba(224, 237, 255, 1);
    }
    .class_name2 {
      color: rgba(238, 113, 241, 1);
      background-color: rgba(238, 113, 241, 0.1);
    }
    .class_name3 {
      color: rgba(9, 215, 228, 1);
      background-color: rgba(9, 215, 228, 0.1);
    }
    .class_name4 {
      color: rgba(231, 158, 17, 1);
      background-color: rgba(231, 158, 17, 0.1);
    }
    .class_name5 {
      color: rgba(231, 158, 17, 1);
      background-color: rgba(231, 158, 17, 0.1);
    }
    .class_name6 {
      color: rgba(231, 158, 17, 1);
      background-color: rgba(231, 158, 17, 0.1);
    }
    .class_name7 {
      color: rgba(153, 153, 153, 1);
      background-color: rgba(153, 153, 153, 0.1);
    }
    .class_name8 {
      color: rgba(33, 122, 247, 1);
      background-color: rgba(224, 237, 255, 1);
    }
    .class_name9 {
      color: rgba(153, 153, 153, 1);
      background-color: rgba(153, 153, 153, 0.1);
    }
    .class_name10 {
      color: rgba(33, 122, 247, 1);
      background-color: rgba(224, 237, 255, 1);
    }
  }

  .order_top {
    background: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .primary_buttom {
      margin-left: 12px;
      border: 1px solid #448aff;
      background: #fff;
    }

    .storeCenter_companyinfo_top_left {
      display: flex;
      flex-direction: row;

      img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        margin-right: 14px;
      }

      .storeCenter_companyinfo_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .img_commer {
          display: flex;
          border: 1px solid #146aff;
          border-radius: 39px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #146aff;
          }
          .color_img {
            background: #146aff;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }
        .img_commers {
          display: flex;
          border: 1px solid #ff9c00;
          border-radius: 39px;
          margin: 0px 10px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #ff9c00;
          }
          .color_img {
            background: #ff9c00;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }

        h4 {
          color: #1f2d3d;
          font-size: 26px;
          font-weight: 500;
          margin: 0;
        }

        .storeCenter_companyinfo_top_left_flex_tag {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          position: relative;
          left: -5px;

          span {
            box-sizing: border-box;
            padding: 1px 6px;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            border: 1px solid #559eff;
            color: #4e93fb;
            cursor: pointer;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }

        .address {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
